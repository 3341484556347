@import "../node_modules/sanitize.css/sanitize.css";

:root {
  --black: hsl(240, 10%, 0%);
  --gray-1: hsl(240, 10%, 10%);
  --gray-2: hsl(240, 10%, 20%);
  --gray-3: hsl(240, 10%, 30%);
  --gray-4: hsl(240, 10%, 40%);
  --gray-5: hsl(240, 10%, 50%);
  --gray-6: hsl(240, 10%, 60%);
  --gray-7: hsl(240, 10%, 60%);
  --gray-8: hsl(240, 10%, 80%);
  --gray-9: hsl(240, 10%, 80%);
  --white: hsl(240, 10%, 100%);
  --green: hsl(148, 59%, 55%);
  --yellow: hsl(45, 80%, 58%);
  --orange: hsl(45, 100%, 75%);
  --blue: hsl(224, 100%, 70%);
  --red: hsl(0, 88%, 58%);
  --red-bg: hsl(0, 100%, 40%);
  --green-bg: hsl(150, 88%, 30%);
  --blue-bg: hsl(200 60% 45%);
  --width: 600px;
  --width-wide: 1200px;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  background: var(--black);
  overscroll-behavior-y: contain;
}

p,
ul,
hr {
  margin: 0;
}

a {
  color: var(--white);
}

#root {
  height: 100%;
}
