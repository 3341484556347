.results {
  width: 100%;
  max-width: var(--width);
  margin: auto;

  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
}
.results.red {
  background-color: var(--red-bg);
}
.results.green {
  background-color: var(--green-bg);
}
.results.blue {
  background-color: var(--blue-bg);
}
.results.wide {
  max-width: var(--width-wide);
}

.title {
  text-align: center;
  font-size: 24px;
}
.info {
  text-align: center;
}

.row {
  display: flex;
  gap: 8px;
  overflow-x: auto;
}

.share {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-2);
  gap: 4px;
  padding: 8px;
  border-radius: 4px;
}

.text {
  margin: 0;
  font-size: 12px;
  text-align: center;
}

.words {
  flex: 1;
  text-align: center;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}
.results.wide .words {
  grid-template-columns: repeat(8, 1fr);
}
.word {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 4px;
  color: var(--white);
}
